@import '../App.css';

.divider1{
    width:75%;
    margin:0 auto;
    border-bottom:solid 5px var(--form-border);
  }

  .vertical-divider1{
    height:25vh;
    width:6px;
    background-color: var(--theme-secondary);
    /* background-image: linear-gradient(to bottom, var(--theme-primary), var(--theme-secondary)); */
  }

.svg-small{
  width:75px;
  height: 75px;
}


.divider2{
  width: 100%;
}

.svg-xs{
  width:30px;
  height: 30px;
}



