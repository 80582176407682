@import'../App.css';

.preview-calendar-header{
    width:100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}


.calendar-post-container{
  max-height:65%;
  width:100%;
  overflow-y: auto;
}


.container-footer{
  width: 100%;
  height:20px;
  border:solid 1px red;
}

/*react package calendar style overrides*/
.input-class{
    border:solid 1px var(--theme-primary);
  }
  
  .calendar-modal-class{
    position:center;
    padding-top:50px;
  }
  
  .header-class{
    background-image: linear-gradient(to right, var(--theme-secondary), var(--theme-primary));
  }


  .days-class{
    border:solid 2px var(--theme-primary);
  }

