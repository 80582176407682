@import '../App.css';

.popup-general{
    width:300px;
    position:absolute;
    z-index:10;
    min-height:40vh;
    border-radius: 15px;
    border:solid 1px var(--theme-secondary);
    background-color: var(--background-secondary);
    margin:0 auto;
    padding:15px;
    transform: translate(0%, -30%);
}