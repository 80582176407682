@import '../App.css';

.nav-container{
    min-height:20px;
    width:100%;
    position:sticky;
    display:flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}

.nav-container.active{
    display: block;
}

.nav-container.mobile-nav{
    display: flex;
}

.nav-container.non-mobile-nav{
    display: none;

}

.nav-liner{
    width:100%;
    height:5px;
    background-image: linear-gradient(to right, var(--theme-primary), var(--theme-secondary));
}

.nav-logo{
    border:solid 1px lime;
}


.side-nav-container{
    width:250px;
    height:90vh;
    overflow-y: auto;
    overflow-x: hidden;
    display: none;
}

.side-nav-container.active{
    display: block;
    position: absolute;
    z-index: 500;
    right:10px;
    transition: visibility 0s, opacity 0.5s linear;
    background-color: var(--background);
    padding:15px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.side-nav-header{
    width:100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 10px;
}

.side-nav-item{
    width:100%;
    display: flex;
    justify-content: center;
}

.side-nav-item h4{
    margin:20px;
}

.side-nav-item:hover{
    background-color: var(--theme-primary);
}

.nav-profile-icon{
    height:50px;
    width:50px;
}


.mobile-nav-icon-btn{
    display: block;
    float: right;
    margin:20px;
}

.mobile-nav-icon{
    height:30px;
    width:30px;
  }


.side-nav-main{
    width:100%;
}

.profile-btn{
    outline:none;
    text-decoration: none;
    border:none;
    background-color: var(--background);
    /* -webkit-text-stroke: 1px gray; */
}

@media (min-width:930px){
    .nav-container{
        display: flex;
    }

    .nav-container.mobile-nav{
        display: none;
    }

    .nav-container.nav-container.non-mobile-nav{
        display: block;
    }

    .side-nav-container{
        display: block;
        float: right;
    }

    .mobile-nav-icon-btn{
        display: none;
    }
}


@media (min-width:750px) and (max-width:919px){
    
}

