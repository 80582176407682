@import '../App.css';

.form-main{
    width:100%;
    margin:0 auto;
    padding: 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    border:solid 2px var(--form-border);
    /* border-image: ;*/
    border-radius: 10px;
} 

.form-main.border-none{
    border:none;
}


@media (min-width:525px){
    .form-main{
        width:450px;
    }
}

@media (min-width:750px){
    .form-main{
        width:700px;
    }
}


.form-inactive{
    display:none;
}


.form-login{
    width:300px;
    margin:0 auto;
    padding: 10px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    border:solid 2px var(--form-border);
    border-radius: 10px;
    max-height: 250px;
}

.form-login.signup{
    max-height: 375px;
}


.form-login.inner-form{
    padding:0px;
    border:none;
    margin:0px;
}



@media (min-width:700px){
    .form-login{
        width: 400px;
    }
}

.form-login.border-none{
    border:none;
}

.form-update{
    width:90%;
    margin:auto;
    padding:10px;
}

@media (min-width:930px){
    .form-update{
        width:75%;
    }
}

.form2{
    width:100%;
    padding:20px;
    margin-top:30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}

@media (min-width:930px){
    .form2{
        width: 75%;
    }
}