.carousel-outer-container{
    width:auto;
    min-height:300px;
    max-height:96vh;
    margin:0 auto;
    display: flex;
    justify-content: center;
}

.carousel-img{
    width: auto;
    height:100%;
    max-height:96vh;
    position: center;
    object-fit: cover;
}


@media (min-width:525px){
    .carousel-outer-container{
        width:450px;
    }

   
}


.card-container{
    overflow: hidden;
    width: 300px;
    min-height:100px;
    max-height:80vh;
    margin-top:10px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap:wrap;
    background-color: var(--background-secondary);
    border-radius: 20px;
    align-items: center;
    /* box-shadow: rgba(0, 0, 0, 0.24) 2px 5px 10px; */
    box-shadow: rgba(0, 0, 0, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}


@media (min-width:450px){
    .card-container{
        width:400px;
    }
}

@media (min-width:880px){
    .card-container{
        width:400px;
        margin-left:10px;
        margin-right:10px;
    }
}