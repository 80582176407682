@import '../App.css';

.btn-main{
    border-radius: 30px;
    padding:10px;
    outline:none;
    text-decoration: none;
    border:none;
    font-weight: 600;
    font-size: large;
    color: var(--text-primary);
    background-image: linear-gradient(to right, var(--theme-primary), var(--theme-secondary));
    /* -webkit-text-stroke: 1px gray; */
}

.btn-main:hover{
    background-color: transparent;
    flex-grow: 1;
}

.btn2 h3{
    font-weight: 500;
    font-size: medium;
}


.btn-2{
    padding:10px;
    outline:none;
    text-decoration: none;
    border:none;
    font-weight: 600;
    font-size: medium;
    background-color: transparent;
    color:var(--text-muted);
    margin:10px;
    max-height: 30px;
}
.btn2{
    border-radius:50px;

}

.btn-2.unmuted{
    color:var(--theme-primary);
    border-bottom:solid 1px var(--theme-primary);
}

.btn-2:hover{
    color:var(--theme-secondary);
    border-bottom:solid 1px var(--theme-secondary);
}


.btn-file-upload{
    border-radius: 30px;
    padding:5px;
    margin:10px;
    border:none;
    outline:none;
    width:70%;
    text-align: center;
    font-weight: 800;
    font-size: large;
}


.input-main{
    border-radius: 30px;
    padding:5px;
    margin:10px;
    border:none;
    outline:none;
    width:100%;
    text-align: center;
    font-weight: 800;
    font-size: large;
}

.input2{
    border-radius: 30px;
    padding:5px;
    margin:10px;
    border:none;
    outline:none;
    width:100%;
    text-align: center;
    font-weight: 400;
    font-size: medium;
}

@media (min-width:500px){
    .input-main{
        width:70%;
    }
}

.input-main.error{
    border:solid 4px rgb(182, 0, 39);
}

.file-label{
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
    width:100%;
}


.file-label input{
    display:none;
}


.upload-btn{
    border-radius:50px;
    background-color: var(--theme-primary);
    padding:10px;
}

.upload-btn h3{
    font-weight: 800;
    font-size: large;
}


.social-select-btn{
    background-color: transparent;
    outline: none;
    border:none;
}

.social-select-btn.selected{
    border:solid 2px var(--text-primary);
}


.btn-tools{
    outline:none;
    text-decoration: none;
    border:none;
    font-weight: 800;
    font-size: larger;
    color: var(--text-primary);
   /*  background-image: linear-gradient(to right, var(--theme-primary), var(--theme-secondary)); */
    /* -webkit-text-stroke: 1px gray; */
    background-color: transparent;
}

.btn-red{
    border:none;
    text-decoration: none;
    padding:10px;
    color: var(--text-primary);
    background-color: var(--alert-primary);
    border-radius:20px;
    font-weight: 800;
    border-radius: 25px;
    max-height:60px;
}


.btn-3{
    border:none;
    text-decoration: none;
    padding:10px;
    color: var(--text-primary);
    background-color: var(--theme-primary);
    border-radius:20px;
    font-weight: 800;
    border-radius: 25px;
    max-height:60px;
}

.social-account-select-item{
    width:100%;
    border:none;
    text-decoration: none;
    padding:10px;
    font-weight: 800;
    color: var(--text-primary);
    border-radius: 5px;
}
.social-account-select-item.hover-bg-1{
    background-color: transparent;
}

.social-account-select-item.hover-bg-2{
    background-color: var(--theme-secondary);
}

.social-account-select-item:hover{
    background-color: var(--theme-secondary);
}
.social-account-select-item:focus{
    border:solid 1px var(--text-secondary)
}



.btn-calendar{
    border-radius: 30px;
    padding:20px;
    outline:none;
    text-decoration: none;
    border:none;
    font-weight: 800;
    font-size: larger;
    color: whitesmoke;
    background-image: linear-gradient(to right, var(--theme-primary), var(--theme-secondary));
    /* -webkit-text-stroke: 1px gray; */
}


.resize-input{
    border-radius: 30px;
    padding:20px;
    width:70%;
    font-size: larger;
    font-weight:600;
}

.resize-input.error{
    border:solid 2px var(--alert-primary);
}

