
@font-face {
    font-family: vermin;
    /* src: local('VerminVibesV-Zlg3'), */
    src: url('./fonts/vermin-vibes/VerminVibesV-Zlg3.ttf') format('truetype');
  /*        url('./fonts/CustomFont1Name.woff2') format('woff2'); */
  }

  @font-face {
    font-family: frankenstein;
    /* src: local('VerminVibesV-Zlg3'), */
    src: url('./fonts/frankenstein/frankenstein.ttf') format('truetype');
  /*        url('./fonts/CustomFont1Name.woff2') format('woff2'); */
  }

  @font-face {
    font-family: coil;
    /* src: local('VerminVibesV-Zlg3'), */
    src: url('./fonts/mortal-coil/MortalCoilDemoRegular-DOGKx.otf') format('truetype');
  /*        url('./fonts/CustomFont1Name.woff2') format('woff2'); */
  }

  @font-face {
    font-family: playfair;
    /* src: local('VerminVibesV-Zlg3'), */
    src: url('./fonts/playfair/PlayfairDisplay-VariableFont_wght.ttf') format('truetype');
  /*        url('./fonts/CustomFont1Name.woff2') format('woff2'); */
  }

  @font-face {
    font-family: work-sans;
    /* src: local('VerminVibesV-Zlg3'), */
    src: url('./fonts/work-sans/WorkSans-VariableFont_wght.ttf') format('truetype');
  /*        url('./fonts/CustomFont1Name.woff2') format('woff2'); */
  }



