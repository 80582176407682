@import '../App.css';


.plan-container{
    width:100%;
    max-width:250px;
}

.plan-container p{
    overflow-wrap: break-word;
    hyphens: manual;
    word-break: break-all;
}



.plan-selected{
    border:3px solid var(--text-primary);
   /*  box-shadow: rgba(255, 255, 255, 0.7) 0px 20px 35px -10px, rgba(173, 173, 173, 0.8) 0px 15px 27px -15px; */
}

@media (min-width:600px){
    .plan-container{
        width:50%;
    }
}

@media (min-width:930px){
    .plan-container{
        width:30%;
    }
}

.plan-bg1{
    background-color: transparent;
}

.plan-bg2{
    background-color: rgb(0, 200, 255);
}

.plan-bg3{
    background-color: var(--background-secondary);
}

.plan-bg4{
    background-color: var(--theme-secondary);
}

.current-plan{
    width:300px;
   /*  border:solid 2px var(--theme-secondary); */
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    border-radius: 5px;
    padding:10px;
}

.billing-info-container{
    width:100%;
    margin-top:20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.subscription-status{
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.subscription-status button {
    margin:20px;
}


@media (min-width:400px){
    .current-plan{
        width:350px;
    }
}

@media (min-width:500){
    .subscription-status{
        width:400px;    
        justify-content: space-around;
    }
}