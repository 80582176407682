@import '../App.css';



.platform-header{
    width:100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
}

.platform-scrollable-container{
    overflow-x: hidden;
    width:100%;
    max-height:65%;
    overflow-y: auto;
    padding:20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}

.platform-popup-container{
    width:320px;
    min-height:100px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    border-radius: 30px;
    background-color: var(--background-secondary);
    margin:0 auto;
}

.platform-select-item{
    padding-left: 15px;
    padding-right: 10px;
    padding-top:5px;
    padding-bottom: 5px;
    width:100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
}


.my-socials-item{
    padding-left: 15px;
    padding-right: 10px;
    padding-top:5px;
    padding-bottom: 5px;
    width:100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    text-align: center;
}

.edit-social-popup-container{
    width:320px;
    min-height:100px;
    padding: 30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    border-radius: 30px;
    background-color: var(--background-secondary);
    margin:0 auto;

}

.popup-main-container{
    position: absolute;
    width:100vw;
    margin:0 auto;
}


