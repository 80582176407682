:root{
  --background: rgb(52, 53, 61);
  --background-secondary:rgb(109, 110, 114);
   --theme-primary: rgb(0, 213, 255);
  --theme-secondary: rgb(0, 173, 115);
  --fade-theme:yellow; 
/*   --theme-primary: rgb(92, 35, 224);
  --theme-secondary: rgb(0, 7, 195);
  --fade-theme:rgb(72, 27, 252); */
  --form-border:rgb(128, 128, 128);
  --text-primary:rgb(236, 237, 238);
  --text-muted:rgb(128, 128, 128);
  --alert-primary: rgb(111, 6, 25);
  
}

@import './css/fonts.css';

h2 {
  font-family: work-sans;
  font-weight:500
}

h3 {
  font-family: work-sans;
  word-wrap: normal;
}

h4 {
  font-family: work-sans;
  word-wrap: normal;
}

h5{
  font-family: work-sans;
  word-wrap: normal;
}

p{
  font-family: work-sans;
  word-wrap: normal;
}

button{
  font-family: work-sans;
}



.logo{
  font-family: coil;
  font-size: 40px;
  color:var(--theme-primary);
  margin-left:30px;
  margin-top:0px;
  margin-bottom: 0px;
}

body{
  background-color: var(--background);
  overflow-x: hidden;
}


body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  background: var(--background-secondary);
}

body::-webkit-scrollbar-thumb {
  background: var(--background);
}

body::-webkit-scrollbar-thumb:hover {
  background: var(--background);
} 

div::-webkit-scrollbar {
  width: 5px;
}

div::-webkit-scrollbar-track {
  background: var(--background-secondary);
}

div::-webkit-scrollbar-thumb {
  background: var(--theme-primary);
}

div::-webkit-scrollbar-thumb:hover {
  background: var(--background);
}

a {
  text-decoration: none;
}

li {
  cursor: pointer;
}

button {
  cursor: pointer;
}

a {
  cursor: pointer;
}

.temp{
  border:solid 2px lime;
}

.main-body-container{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap-reverse;
  width: 100%;
  height:100%;
}

.child-body-container{
  display: flex;
  justify-content: center;
  width:100%;
}

.child-body-container.nav{
  display:flex;
  width:100%;
}



@media (min-width:930px){
  .main-body-container{
    flex-wrap: nowrap;
  }


  .child-body-container{
    width:85%;
    display: inline-flex;
  }

  .child-body-container.nav{
    width:15%;
    display: inline-flex;
    justify-content: center;
  }
}