.error-container{
    width:100%;
    display:flex;
    justify-content: center;
    background-color: rgb(255, 163, 163);
    border:solid 1px red;
}

.error h3{
    color:red;
    font-weight: bolder;
    padding:0px;
    margin:0px;
}

.error-container h3{
    color:red;
    font-weight: bolder;
}

.success-container{
    width:100%;
    display:flex;
    justify-content: center;
    background-color: rgb(151, 254, 163);
    border:solid 1px rgb(25, 201, 37);
    margin:10px;
}

.success-container h3{
    color: white;
    font-weight: bolder;
    margin:5px;
    padding:0px;
}