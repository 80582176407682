@import '../App.css';

.landing-subcontainer{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width:100%;
    margin:30px;
    margin-top:70px !important;
}

.landing-subcontainer h4{
    font-weight: 400;
}

.landing-knot{
    width:100px;
    height:80px;
}

.landing-social-container{
    width:100%;
    display:flex;
    justify-content: center;
    flex-wrap: wrap;
}

@media (min-width:500px){
    .landing-social-container{
        width:50%;
    }
}

@media (min-width:650px){
    .landing-subcontainer{
        width: 50%;
        margin: 0px;
    }
}