.checkout-container{
    /* background-color: white; */
    background-color: var(--background-secondary);
    margin-top:20px;
    width:100%;
    justify-content: center;
    flex-wrap: wrap;
    border-radius: 30px;
    margin:auto;
}


.plan-details-wrapper{
    width:100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.plan-details-item{
    width:100%;
    display: flex;
    justify-content: center;
}


.purchase-popup-container{
    width:75%;
    display: flex;
    justify-content: center;
    margin:auto;
}

.popup-content-container{
    width:100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin:auto;
}

.card-input-wrapper{
    width:100%;
}


.update-card-popup{
    width:300px;
    position:absolute;
    z-index:10;
    min-height:40vh;
    border-radius: 15px;
    border:solid 1px var(--theme-secondary);
    background-color: var(--background-secondary);
    margin:0 auto;
    padding:15px;
    transform: translate(0%, -60%);
}

@media (min-width:500px){
    .update-card-popup{
        width: 450px;
    }
}

@media (min-width:500px){
    .plan-details-wrapper{
        width:75%;
        justify-content: space-between;
    }

    .plan-details-item{
        width:50%;
        justify-content: center;
    }

    .checkout-container{
        width:500px;
    }

    .card-input-wrapper{
        width:100%;
        margin: 0 auto;
        padding:40px;
        /* display:flex;
        justify-content: center;
        flex-wrap: wrap; */
    }
}

