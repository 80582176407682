@import '../App.css';


.w-100{
    width: 100%;
}

.w-75{
    width: 75%;
}

.w-50{
    width:50%;
}

.w-25{
    width:25%;
}

.h-100{
    height:100%;
}

.h-90{
    height:90%;
}

.h-50{
    height:50%;
}

.max-h-50{
    max-height: 50px;
}

.justify-content-between{
    display: flex;
    justify-content: space-between;
}

.justify-content-center{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.justify-content-start{
    display: flex;
    justify-content: flex-start;
}

.justify-content-end{
    display: flex;
    justify-content: flex-end;
}

.space-between{
    display: flex;
    justify-content: space-between;
}

.justify-content-space-around{
    display: flex;
    justify-content: space-around;
}

.align-items{
    align-items: center;
    text-align: center;
}

.flex-wrap{
    flex-wrap: wrap;
}

.felx-no-wrap{
    flex-wrap: nowrap;
}

.flex-wrap-reverse{
    flex-wrap: wrap-reverse;
}

.display-inline{
    display: inline;
}

.mt-10{
    margin-top: 10px;
}

.mt-20{
    margin-top:20px;
}

.mt-50{
    margin-top: 50px;
}

.m-10{
    margin: 10px;
}

.m-15{
    margin: 15px;
}

.m-20{
    margin: 20px;
}

.m-30{
    margin:30px;
}

.m-50{
    margin:50px;
}

.m-0{
    margin:0px;
}

.p-0{
    padding:0px;
}

.p-10{
    padding:10px;
}

.p-15{
    padding:10px;
}

.p-20{
    padding:20px;
}

.p-30{
    padding:30px;
}

.temp1{
    border:solid 1px lime;
}

.temp2{
    border:solid 1px red;
}

.bd-primary{
    border:solid 2px var(--main-theme);
}

.bg-white{
    background-color: whitesmoke;
}

.text-white{
    color:whitesmoke;
}
.text-white::placeholder{
    color:whitesmoke;
}

.text-primary{
    color:var(--text-primary);
}

.text-secondary{
    color:var(--text-secondary);
}

.text-theme-secondary{
    color:var(--theme-secondary);
}

.text-title{
    color:var(--theme-primary);
    font-weight: 900;
}

.text-theme-secondary{
    color:var(--theme-secondary);
}

.div-link{
    background-color: transparent;
}

.div-link:hover{
    background-color: var(--background);
    opacity: .80;
    transition: ease-in-out;
}

.scrollable-y{
    overflow-y: scroll;
}

.scrollable-x{
    overflow-x: scroll;
}




@media (min-width:930px){
    .mobile-only{
        display: none;
    }
}